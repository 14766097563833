import { ReactElement, createContext, useEffect, useMemo } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom-v5-compat';

import { RootState, useAppDispatch, useAppSelector } from '../../redux/store';
import { fetchAuthType } from '../../redux/Auth/actions';
import { HomeRoutes } from '../../Routing/RouteConstants';
import AppLoading from '../../Modules/Home/HomeLayouts/AppLoading';
import NotSetup from 'Modules/NotSetup/NotSetup';

export const AuthTypeContext = createContext<null>(null);

type Props = {
  children: ReactElement;
};

const AuthTypeProvider = ({ children }: Props) => {
  const dispatch = useAppDispatch();

  const isAdminLogin = useMemo(() => {
    return window.location.pathname.includes(HomeRoutes.adminLogin);
  }, []);

  const isLoading = useAppSelector((state: RootState) => state.userInfo.authTypeIsLoading);
  const hasError = useAppSelector((state: RootState) => state.userInfo.authTypeHasError);

  useEffect(() => {
    dispatch(fetchAuthType());
  }, [dispatch]);

  const { Provider } = AuthTypeContext;

  if (isLoading) {
    return <AppLoading />;
  }

  if (hasError && !isAdminLogin) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<NotSetup />} />
          <Route
            path={HomeRoutes.terms}
            lazy={async () => {
              const TermsAndConditions = (
                await import('../../Modules/Home/TermsAndConditions/TermsAndConditions')
              ).default;
              const HomeLayoutLoginRoutes = (
                await import('../../Modules/Home/HomeLayouts/HomeLayoutLoginRoutes')
              ).default;

              return { element: <HomeLayoutLoginRoutes content={<TermsAndConditions />} /> };
            }}
          />
        </Routes>
      </BrowserRouter>
    );
  }
  return <Provider value={null}>{children}</Provider>;
};

export default AuthTypeProvider;
