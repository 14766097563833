import Rbac from '../RBAC/rbac';

export const StudioRoutes = {
  base: 'studio',
  permissions: [
    ['models', 'model', 'r'],
    ['queries', 'query', 'r'],
    ['apis', 'api', 'r'],
  ],

  dataSources: {
    base: 'data-sources',
  },
  model: {
    base: 'domains',
    permission: ['models', 'model', 'r'],
  },
  editDomain: {
    base: 'edit',
    basePathVariables: '/:domainId',
    permission: ['models', 'model', 'r'],
  },
  editDomainMapping: {
    base: 'mappings',
    basePathVariables: '/:conceptURI',
    permission: ['models', 'model', 'r'],
  },

  questions: {
    base: 'questions',
    basePathVariables: ':domainId/:domainName',
    permission: ['queries', 'query', 'r'],
  },
  createQuestion: {
    base: 'edit',
    basePathVariables: '/:queryId',
    permission: ['queries', 'query', ['c', 'r']],
  },
  editQuestionMapping: {
    base: 'mappings',
    basePathVariables: '',
    permission: ['queries', 'query', ['c', 'r']],
  },
  editQuestionPreview: {
    base: 'preview',
    basePathVariables: '',
    permission: ['queries', 'query', ['c', 'r']],
  },
  editQuestionCards: {
    base: 'cards',
    basePathVariables: '',
    permission: ['queries', 'query', ['c', 'r']],
  },

  mapping: {
    base: 'mapping',
    basePathVariables: '/:solutionId/:queryId',
    properties: 'properties',
    dataSources: 'data-sources',
    dataSourceTables: {
      basePathVariables: '/:section/:dataSourceId',
    },
    dataSetSettings: {
      basePathVariables: ':section/:dataSourceId/:dataSourceName/:tableName/:dataSourceTypeId',
    },
    dataSets: 'data-sets',
    permission: [
      ['queries', 'query', 'r'],
      ['mappings', 'mapping', 'r'],
      ['datasources', 'datasource', ['r', 'c', 'u', 'd']],
    ],
  },
  api: {
    base: 'api-details',
    permission: ['apis', 'api', 'r'],
  },
  auditLog: {
    base: 'audit-log',
    permission: ['queries', 'query', ['c', 'r']],
  },
  cardBuilder: {
    base: 'card-builder-poc',
    permission: ['queries', 'query', ['c', 'r']],
  },
};

export const BlossomRoutes = {
  confirmSignup: 'confirmSignup',
  podManagement: {
    base: 'account-settings',
    permission: [
      ['accounts', 'account', 'r'],
      ['tenants', 'tenant', 'r'],
      ['security', 'security', 'r'],
      ['admins', 'admin', 'r'],
    ],
    account: {
      base: 'information',
      permissions: ['accounts', 'account', 'r'],
    },
    tenant: {
      base: 'tenant-settings',
      permissions: ['tenants', 'tenant', 'r'],
    },
    security: {
      base: 'security-settings',
      permissions: ['security', 'security', 'r'],
    },
    admin: {
      base: 'admin-manager',
      permissions: ['admins', 'admin', 'r'],
    },
    branding: {
      base: 'branding',
      permissions: ['security', 'security', 'r'], //same permissions as updating security
    },
    cardRecipes: {
      base: 'card-recipes',
      permissions: ['security', 'security', 'r'], //same permissions as updating security
    },
  },
  tenantAdmin: {
    base: 'settings',
    permissions: ['users', 'user', 'r'],
    user: {
      base: 'user-management',
      permissions: ['users', 'user', 'r'],
    },
    dataSecurity: {
      base: 'data-security',
      permissions: ['users', 'user', 'r'],
    },
    recovery: {
      base: 'recovery',
      basePathVariables: '/:tab',
      permissions: ['users', 'user', 'r'], //same permissions as updating users
    },
    snapshot: {
      base: 'snapshot',
      permissions: ['users', 'user', 'r'], //same permissions as updating users
    },
    import: {
      base: 'import',
      permissions: ['users', 'user', 'r'], //same permissions as updating users
    },
    cleanIngestion: {
      base: 'clean-ingestion',
      permissions: ['users', 'user', 'r'], //same permissions as updating users
    },
    security: {
      base: 'security',
      basePathVariables: '/:tab',
      permissions: ['users', 'user', 'r'], //same permissions as updating users
    },
    sslCerts: {
      base: 'ssl-certs',
      permissions: ['users', 'user', 'r'], //same permissions as updating users
    },
  },
  userSettings: {
    base: 'user-settings',
    permissions: [],
    user: {
      base: 'information',
      permissions: [],
    },
    password: {
      base: 'password',
      permissions: [],
    },
  },
};

export const TowerRoutes = {
  base: 'tower360',
  dashboard: {
    base: 'dashboard',
    decisionBoards: {
      base: ':collectionName/:collectionId?',
      home: 'overview',
    },
  },
};

export const HomeRoutes = {
  home: '/',
  welcome: '',
  support: 'support',
  login: 'login',
  eoAuthLogin: 'user-mgmt-svcs/auth/oauth/external/login',
  eoAuthLogout: 'user-mgmt-svcs/auth/oauth/external/logout',
  ssoLogout: 'sso-logout',
  oAuthcb: 'oauthcb',
  adminLogin: 'admin-login',
  terms: 'terms-of-use',
  privacy: 'privacy',
  pageNotFound: 'page-not-found',
  confirmSignUp: 'confirmSignup',
  forgotPassword: 'forgot-password',
  notAuthorized: 'not-authorized',
  notSetup: 'not-setup',
};

export const TowerHomeRoute = `/${TowerRoutes.base}/${TowerRoutes.dashboard.base}/${TowerRoutes.dashboard.decisionBoards.home}`;

export const getStudioHomeRoute = () => {
  if (Rbac.hasPermission(['models', 'model', 'r'])) {
    return `/${StudioRoutes.base}/${StudioRoutes.model.base}`;
  } else {
    return `/${StudioRoutes.base}/${StudioRoutes.api.base}`;
  }
};

export const TenantAdminHomeRoute = `/${BlossomRoutes.tenantAdmin.base}/${BlossomRoutes.tenantAdmin.user.base}`;

export const UserSettingsHomeRoute = `/${BlossomRoutes.userSettings.base}/${BlossomRoutes.userSettings.user.base}`;

export const PodManagementHomeRoute = `/${BlossomRoutes.podManagement.base}/${BlossomRoutes.podManagement.security.base}`;
