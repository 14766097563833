import { IBaseReduxProgressStateType } from '@redux/root-redux-types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IIngestionCounts {
  ingestionErrorCount: number;
  ingestionRunningCount: number;
  ingestionWarningCount: number;
  scheduledIngestionCount: number;
}

interface IEventCounts {
  counts: IIngestionCounts;
}

interface IngestionCountsState extends IEventCounts, IBaseReduxProgressStateType {
  colorPriorities: string;
}

const colorPriorities = {
  error: 'rgba(232, 165, 48, 0.05)',
  warning: 'rgba(201, 29, 29, 0.05)',
  none: 'transparent',
};

const initialState: IngestionCountsState = {
  counts: {
    ingestionErrorCount: 0,
    ingestionRunningCount: 0,
    ingestionWarningCount: 0,
    scheduledIngestionCount: 0,
  },
  colorPriorities: 'transparent',
  loading: false,
  hasErrors: false,
};

export const ingestionEventsReducer = {
  resetState: (state: IngestionCountsState) => {
    state.counts = initialState.counts;
    state.colorPriorities = initialState.colorPriorities;
    state.loading = initialState.loading;
    state.hasErrors = initialState.hasErrors;
  },

  setCounts: (state: IngestionCountsState, action: PayloadAction<IEventCounts>) => {
    const counts = action.payload.counts;
    state.counts = counts;

    if (counts.ingestionErrorCount > 0) {
      state.colorPriorities = colorPriorities.error;
    } else if (counts.ingestionWarningCount > 0) {
      state.colorPriorities = colorPriorities.warning;
    }

    state.loading = false;
  },

  initFetch: (
    state: IngestionCountsState,
    action: PayloadAction<{ ingestionRunningCount?: number }>
  ) => {
    state.loading = true;
    state.hasErrors = false;

    if (action.payload.ingestionRunningCount) {
      state.counts.ingestionRunningCount = action.payload.ingestionRunningCount;
    }
  },

  errorFetching: (state: IngestionCountsState) => {
    state.loading = false;
    state.hasErrors = true;
  },
};

export const IngestionEventsSlice = createSlice({
  name: 'ingestionEvents',
  initialState,
  reducers: ingestionEventsReducer,
});

export const { resetState, setCounts, initFetch, errorFetching } = IngestionEventsSlice.actions;

export default IngestionEventsSlice.reducer;
