import { FC } from 'react';
import HelpIcon from '@mui/icons-material/Help';
import { StudioModalLegacyType } from './interface/StudioModal.interface';

type Props = {
  showHelp?: boolean;
  toggleShowHelp?: (showHelp: boolean) => void;
  setHelpButtonRef?: (ref: HTMLButtonElement | null) => void;
};

const HelpButton: FC<Props & StudioModalLegacyType> = ({
  showHelp = false,
  toggleShowHelp,
  setHelpButtonRef,
  isLegacy = true,
}) => {
  return (
    <button
      ref={(ref) => setHelpButtonRef && setHelpButtonRef(ref)}
      data-testid="toggle-show-help"
      className={
        'help-btn relative flex items-center justify-center pointer br bl b--tints-default-dark bt-0 bb-0 width-60 h-inherit' +
        (showHelp
          ? isLegacy
            ? ' active bg-lavender'
            : ' bg-shade-default tints-default-ultra-light'
          : '')
      }
      onClick={() => toggleShowHelp && toggleShowHelp(!showHelp)}
    >
      <HelpIcon
        style={{
          fontSize: 28,
        }}
      />
    </button>
  );
};

export default HelpButton;
