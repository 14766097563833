import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  IDataSetWithTags,
  IDataSetWithTagsConfig,
} from '../../constants/AccessTagsDrawer.interface';

type selectedDataSetType = Pick<
  IDataSetWithTags,
  'dataSetId' | 'dataSetColumns' | 'dataSourceType' | 'dataSetTableName' | 'dataSourceName'
>;

interface IAccessTagsDrawerState {
  isModalOpen: boolean;
  selectedConceptName: string;
  selectedConceptUri: string;
  selectedDataSet: selectedDataSetType;
  selectedConfig: IDataSetWithTagsConfig;
  showAccessTagsDrawer: boolean;
  filterBy: string;
}

export const initialState: IAccessTagsDrawerState = {
  isModalOpen: false,
  showAccessTagsDrawer: false,
  selectedConceptName: '',
  selectedConceptUri: '',
  filterBy: '',
  selectedConfig: {
    id: null,
    filters: [],
    dataTags: [],
  },
  selectedDataSet: {
    dataSetId: null,
    dataSourceName: '',
    dataSetTableName: '',
    dataSetColumns: [],
    dataSourceType: -1,
  },
};

export const accessTagsDrawerReducer = {
  resetState: (state: IAccessTagsDrawerState) => {
    state.isModalOpen = initialState.isModalOpen;
    state.selectedConceptName = initialState.selectedConceptName;
    state.selectedConceptUri = initialState.selectedConceptUri;
    state.showAccessTagsDrawer = initialState.showAccessTagsDrawer;
    state.selectedConfig = initialState.selectedConfig;
    state.selectedDataSet = initialState.selectedDataSet;
    state.filterBy = initialState.filterBy;
  },

  closeModal: (state: IAccessTagsDrawerState) => {
    state.isModalOpen = initialState.isModalOpen;
    state.selectedConfig = initialState.selectedConfig;
    state.selectedDataSet = initialState.selectedDataSet;
  },

  openModalForEditing: (
    state: IAccessTagsDrawerState,
    action: PayloadAction<{
      selectedDataSet: selectedDataSetType;
      selectedConfig: IDataSetWithTagsConfig;
      selectedConceptName: string;
      selectedConceptUri: string;
    }>
  ) => {
    state.isModalOpen = true;
    state.selectedDataSet = action.payload.selectedDataSet;
    state.selectedConfig = action.payload.selectedConfig;
    state.selectedConceptName = action.payload.selectedConceptName;
    state.selectedConceptUri = action.payload.selectedConceptUri;
  },

  openModalForNew: (
    state: IAccessTagsDrawerState,
    action: PayloadAction<{
      selectedDataSet: selectedDataSetType;
      selectedConceptName: string;
      selectedConceptUri: string;
    }>
  ) => {
    state.isModalOpen = true;
    state.selectedDataSet = action.payload.selectedDataSet;
    state.selectedConfig = initialState.selectedConfig;
    state.selectedConceptName = action.payload.selectedConceptName;
    state.selectedConceptUri = action.payload.selectedConceptUri;
  },

  toggleAccessTagsDrawer: (state: IAccessTagsDrawerState) => {
    state.showAccessTagsDrawer = !state.showAccessTagsDrawer;
  },

  closeAccessTagsDrawer: (state: IAccessTagsDrawerState) => {
    state.showAccessTagsDrawer = false;
  },

  setFilterBy: (
    state: IAccessTagsDrawerState,
    action: PayloadAction<{
      filterBy: string;
    }>
  ) => {
    state.filterBy = action.payload.filterBy;
  },
};

export const accessTagsDrawerSlice = createSlice({
  name: 'accessTagsDrawer',
  initialState,
  reducers: accessTagsDrawerReducer,
});

export const {
  resetState,
  openModalForEditing,
  openModalForNew,
  closeModal,
  toggleAccessTagsDrawer,
  setFilterBy,
  closeAccessTagsDrawer,
} = accessTagsDrawerSlice.actions;

export default accessTagsDrawerSlice.reducer;
