let base = {
  //Users and Auth
  userMgmt: '/user-mgmt-svcs',

  // studio mapping
  data: '/data-svcs',

  // saturn
  saturn: '/saturn-svcs',

  episteme: '/episteme-svcs',
};

const apiEndpoints = {
  // episteme
  episteme: base.episteme + '/',

  //Auth
  authSetup: base.userMgmt + '/setup/auth',
  authType: base.userMgmt + '/setup/auth/type',
  eoAuthLogout: base.userMgmt + '/auth/oauth/external/logout',
  refreshToken: base.userMgmt + '/auth/oauth/external/refresh/token',

  //Local Login
  login: base.userMgmt + '/auth/local',
  ssoOauthLogin: base.userMgmt + '/auth/oauth/external/access/token',
  confirmSignup: base.userMgmt + '/auth/confirmSignup',
  resetPwd: base.userMgmt + '/auth/resetPwd',
  recoverPwd: base.userMgmt + '/auth/recoverPwd',
  loginSuperUser: base.userMgmt + '/auth/superuser',
  getTokenForSSO: base.userMgmt + '/auth/oauth/external/token',

  //Roles
  roles: base.userMgmt + '/roles',

  //Tenants
  tenants: base.userMgmt + '/tenant',
  tenantAdmins: base.userMgmt + '/tenant/admin',
  tenantAdminInfo: base.userMgmt + '/tenant/user',
  tenantSetup: base.userMgmt + '/tenant/setup',

  //Users
  mgmtUsers: base.userMgmt + '/user',
  userProfile: base.userMgmt + '/user/profile',
  userRoles: base.userMgmt + '/user/roles',
  userRolesCount: base.userMgmt + '/user/roles/count',
  userTenantInfo: base.userMgmt + '/user/tenant',
  userTenants: base.userMgmt + '/user/tenants',
  userTenantByRole: base.userMgmt + '/user/tenant/role',
  doesUserExist: base.userMgmt + '/user/userName',

  // brand
  brand: base.userMgmt + '/brand',

  // audit
  dataMgmt: base.data + '/mgmt',

  // studio
  queries: base.data + '/queries',
  studioApi: base.data + '/api',
  bulkQueryPermissionList: base.data + '/queries/permission/bulk',

  // studio - domains
  domain: base.data + '/model/domain',

  // studio - models
  category: base.data + '/category',
  models: base.data + '/models',

  // studio - mapping
  dataSet: base.data + '/dataSet',
  dataSets: base.data + '/dataSets',
  dataSource: base.data + '/dataSource',
  dataSourceType: base.data + '/dataSourceType',
  mapping: base.data + '/mapping',
  datatagWithoutQuery: base.data + '/datatag',
  datatag: base.data + '/datatag/query',
  datatagNoQuery: base.data + '/datatag',
  solution: base.data + '/solution',
  stopIngestion: base.data + '/solution/stopIngestion',
  startIngestion: base.data + '/solution/runIngestion',
  solutions: base.data + '/solutions',
  events: base.data + '/events',

  // studio - viz
  collection: base.data + '/collection',

  // studio - logs
  statusLogs: base.data + '/events/ingestion/summary',
  statusLogCounts: base.data + '/events',
  statusLogSelected: base.data + '/events/ingestion',
  ingestionStatus: base.data + '/events/ingestion/solution/dataSource',

  // studio - simulations
  simulationScenario: base.data + '/simulation/scenario',

  // blossom
  apiDetails: base.data + '/api',

  //constants
  timeStampFormats: base.data + '/constants/timeformat',

  //collections
  collections: base.data + '/collection',
  collectionsNav: base.data + '/collection/nav',
  collectionsFilters: base.data + '/collection/filters',
  collectionsSummary: base.data + '/collection/summary',
  collectionSearch: base.data + '/collection/search',

  //visualization
  visualization: base.data + '/visualization',
  visualizationCollection: base.data + '/visualization/collection',
  allVisualizationCollection: base.data + '/visualization/all/collection',
  visualizationsByName: base.data + '/visualization/collection/name',
  recentVisualizations: base.data + '/visualization/recent',

  // simulations
  simulatedTagsByConceptId: base.data + '/simulation/scenario/collection',

  // saturn
  chat: base.saturn + '/chat',
  chatexec: base.saturn + '/chatexec',
};

export { apiEndpoints as url };

export default apiEndpoints;
