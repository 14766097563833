export const number = 'number';
export const bool = 'boolean';
export const dateTime = 'dateTime';
export const str = 'string';
export const timeseries = 'timeseries';
export const relation = 'relation';

export const propTypes = [
  { value: str, label: 'Text' },
  { value: number, label: 'Number' },
  { value: dateTime, label: 'Date/Time' },
  { value: bool, label: 'Boolean' },
  { value: timeseries, label: 'Timeseries' },
];

// in case order of propTypes above changes, prevent against aggregateOptions.propTypes being affected
const numberPropTypeIndex = propTypes.findIndex((prop) => prop.value === number);
const dateTimeTypeIndex = propTypes.findIndex((prop) => prop.value === dateTime);
const stringTypeIndex = propTypes.findIndex((prop) => prop.value === str);
const allPropTypes = propTypes.map((prop) => prop.value);
const excludeBoolPropTypes = propTypes
  .filter((prop) => prop.value !== bool)
  .map((prop) => prop.value);
export const timeseriesPropType = propTypes
  .filter((prop) => prop.value === timeseries)
  .map((prop) => prop.value);

export const aggregateOptions = [
  { value: 'count', label: 'count', propTypes: allPropTypes },
  { value: 'max', label: 'max', propTypes: [propTypes[numberPropTypeIndex].value] },
  { value: 'min', label: 'min', propTypes: [propTypes[numberPropTypeIndex].value] },
  { value: 'sum', label: 'sum', propTypes: [propTypes[numberPropTypeIndex].value] },
  { value: 'avg', label: 'avg', propTypes: [propTypes[numberPropTypeIndex].value] },
  { value: 'group_concat', label: 'group concat', propTypes: [propTypes[stringTypeIndex].value] },
  { value: 'sample', label: 'sample', propTypes: [propTypes[stringTypeIndex].value] },
];

export const filterByOptions = [
  { value: '=', propTypes: allPropTypes },
  {
    value: '<',
    propTypes: [propTypes[numberPropTypeIndex].value, propTypes[dateTimeTypeIndex].value],
  },
  {
    value: '>',
    propTypes: [propTypes[numberPropTypeIndex].value, propTypes[dateTimeTypeIndex].value],
  },
  {
    value: '<=',
    propTypes: [propTypes[numberPropTypeIndex].value, propTypes[dateTimeTypeIndex].value],
  },
  {
    value: '>=',
    propTypes: [propTypes[numberPropTypeIndex].value, propTypes[dateTimeTypeIndex].value],
  },
  { value: '!', propTypes: allPropTypes },
  { value: 'between', propTypes: excludeBoolPropTypes },
];

export const userValue = '##KOBAICONSTANT##';
export const functionValue = '##Function##';

export const functionTypes = [
  { value: 'concat', label: 'Concatenation' },
  { value: 'lower', label: 'Lower' },
  // { value: 'math', label: 'Math' }, // temporarily hiding due to many bugs w/math, for #1297
  // Note: IF math is added back, as part of #1994, updates will have to be made so that selectedMapping.relationLookups use case
  // can be implemented
  { value: 'regex', label: 'Regex' },
  { value: 'split', label: 'Split Index' },
  { value: 'trim', label: 'Trim' },
  { value: 'upper', label: 'Upper' },
];

export const timeStampFormats = [
  { value: 'yyyy/MM/dd HH:mm:ss', label: 'yyyy/MM/dd HH:mm:ss' },
  { value: 'yyyy/MM/dd hh:mm:ss a', label: 'yyyy/MM/dd hh:mm:ss a' },
  { value: 'dd-MM-yyyy HH:mm:ss', label: 'dd-MM-yyyy HH:mm:ss' },
  { value: 'dd-MM-yyyy hh:mm:ss a', label: 'dd-MM-yyyy hh:mm:ss a' },
  { value: 'yyyy-MM-dd HH:mm:ss', label: 'yyyy-MM-dd HH:mm:ss' },
  { value: 'yyyy-MM-dd hh:mm:ss a', label: 'yyyy-MM-dd hh:mm:ss a' },
  { value: 'MM/dd/yyyy HH:mm:ss', label: 'MM/dd/yyyy HH:mm:ss' },
  { value: 'MM/dd/yyyy hh:mm:ss a', label: 'MM/dd/yyyy hh:mm:ss a' },
  { value: "yyy-MM-dd'T'HH:mm:ss'Z'", label: "yyy-MM-dd'T'HH:mm:ss'Z'" },
];

// 2019-09-18T19:00:52Z

// WARNING: if the order of tsFetchTypeOptions ever changes, it will mess up values that are passed to the BE.
// The BE stores values as the array index ['sum'=0, 'average'=1, 'min'=2, 'max'=3]
export const tsFetchTypeOptions = ['sum', 'average', 'min', 'max'];
export const fetchTypesWithValues = tsFetchTypeOptions.reduce((acc, opt, index) => {
  return {
    ...acc,
    [index]: opt,
  };
}, {});
