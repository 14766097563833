import {
  IPropertyMapping,
  ISelectedMappingNoQuery,
  MappingPropConceptInfoType,
} from './properties.interface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { emptySelectedProperty, mappingDefBase, mappingInitialState } from './initialState';
import { IDataSet, IMappingState } from './mapping.interface';

export const updateMappingReduxSlice = {
  resetState: (state: IMappingState) => {
    state.selectedProperty = mappingInitialState.selectedProperty;
    state.selectedMapping = mappingInitialState.selectedMapping;
    state.dataSets = mappingInitialState.dataSets;
    state.lookups = mappingInitialState.lookups;
    state.conceptIdOverlaps = mappingInitialState.conceptIdOverlaps;
    state.loading = mappingInitialState.loading;
    state.showMappingModal = false;
  },

  openMappingModal: (state: IMappingState) => {
    state.showMappingModal = true;
  },

  setIsLoadingMapping: (state: IMappingState, action: PayloadAction<boolean>) => {
    state.loading = action.payload;
  },

  setSelectedProperty: (
    state: IMappingState,
    action: PayloadAction<{
      selectedProperty: IPropertyMapping;
      selectedConcept: MappingPropConceptInfoType;
    }>
  ) => {
    state.selectedProperty = action.payload?.selectedProperty || emptySelectedProperty;
    state.selectedMapping = {
      ...mappingDefBase,
      ...state.selectedMapping,
      conceptUri: action.payload.selectedConcept.uri,
      conceptTypeUri: action.payload.selectedConcept.conceptUri,
      propertyUri: action.payload.selectedProperty.uri,
      // NOTE: when fetching props, key name is propTypeUri BUT when saving a def, BE requires 'propertyTypeUri'
      propertyTypeUri: action.payload.selectedProperty.propTypeUri,

      // used for displaying prop name & concept
      modalLabels: {
        propName: action.payload.selectedProperty.label,
        conceptName: action.payload.selectedConcept.label,
      },
    };
    state.showMappingModal = true;
  },

  initFetchingDataSetColumns: (state: IMappingState) => {
    state.dataSets.dataSetColumns.list = [];
    state.dataSets.dataSetColumns.errors = [];
    state.dataSets.dataSetColumns.options = [];
    state.dataSets.dataSetColumns.loading = true;
    state.dataSets.dataSetColumns.hasError = false;
  },

  setHasErrorLoadingDataSetColumns: (state: IMappingState, action: PayloadAction<boolean>) => {
    state.dataSets.dataSetColumns.hasError = action.payload;
    state.dataSets.dataSetColumns.loading = false;
  },

  setInitialSelectedMappingInfo: (
    state: IMappingState,
    action: PayloadAction<{
      mappingDefinition: ISelectedMappingNoQuery;
      dataSetMap?: Record<number, IDataSet>;
      datasetId?: number;
    }>
  ) => {
    const { mappingDefinition: mappingDef, dataSetMap, datasetId } = action.payload;
    const selectedDataSet = datasetId && dataSetMap ? dataSetMap[datasetId] : null;
    const dataSetTimestampFormat = selectedDataSet?.dataSetProps?.timeFormat ?? null;
    const dataSetTimeZone = selectedDataSet?.dataSetProps?.timezone ?? null;

    state.selectedMapping = {
      // Set mappingDefBase as defaults
      ...mappingDefBase,

      // Merge in mappingDef from the backend
      ...mappingDef,

      // Convert to 0 if it used to be 1 (old logic)
      mappingIdTypeId: mappingDef.mappingIdTypeId === 1 ? 0 : mappingDef.mappingIdTypeId,

      // Keep existing properties used for labeling
      modalLabels: state.selectedMapping?.modalLabels || {
        propName: '',
        conceptName: '',
      },

      // Override defaults from dataSets
      timestampProps: {
        timestampFormat:
          dataSetTimestampFormat !== null && dataSetTimestampFormat !== undefined
            ? dataSetTimestampFormat
            : mappingDef.timestampProps?.timestampFormat,
        timeZone:
          dataSetTimeZone !== null && dataSetTimeZone !== undefined
            ? dataSetTimeZone
            : mappingDef.timestampProps?.timeZone,
        alignTo: mappingDef.timestampProps?.alignTo,
      },
    };

    // lookups
    state.lookups.relations.overlapChecked = !!mappingDef.relationLookups || false;
  },

  setMappingInfoLocal: (
    state: IMappingState,
    action: PayloadAction<Partial<ISelectedMappingNoQuery>>
  ) => {
    (Object.keys(action.payload) as Array<keyof ISelectedMappingNoQuery>).forEach((key) => {
      const typedKey = key as keyof ISelectedMappingNoQuery;
      const value = action.payload[key];

      if (value !== undefined && state.selectedMapping !== null) {
        (state.selectedMapping[typedKey] as typeof value) = value;
      }
    });
  },

  setDatasetColumnsForMapping: (state: IMappingState, action: PayloadAction<any>) => {
    // TODO: remove sorting once it comes back sorted from BE
    const dataSetColumns = [...action.payload].sort((a: string, b: string) =>
      a.toLowerCase().localeCompare(b.toLowerCase())
    );
    state.dataSets.dataSetColumns.loading = false;
    state.dataSets.dataSetColumns.list = dataSetColumns;
    state.dataSets.dataSetColumns.options = dataSetColumns.map((column: string) => ({
      label: column,
      value: column,
    }));
  },
  setHasMappingError: (state: IMappingState, action: PayloadAction<boolean>) => {
    state.errors = state.errors || [];
    state.errors.push({
      type: 'loading',
      message: 'Error fetching mapping data',
    });
  },
};

export const updateRelationLookupsSlice = {
  setLookupsIsLoading: (state: IMappingState, action: PayloadAction<boolean>) => {
    state.lookups.loading = action.payload;
    state.lookups.error = null;
  },
  setGeneralLookupsDataSetColumns: (state: IMappingState, action: PayloadAction<any>) => {
    state.lookups.loading = false;
    state.lookups.relations.dataSetColumns.list = action.payload;
    state.lookups.relations.dataSetColumns.options = action.payload.map((column: string) => ({
      label: column,
      value: column,
    }));
  },
  setConceptIdLookupsDataSetColumns: (state: IMappingState, action: PayloadAction<any>) => {
    state.lookups.loading = false;
    state.lookups.identifier.dataSetColumns.list = action.payload;
    state.lookups.identifier.dataSetColumns.options = action.payload.map((column: string) => ({
      label: column,
      value: column,
    }));
  },
  setLookupsHasError: (state: IMappingState, action: PayloadAction<any>) => {
    state.lookups.loading = false;
    state.lookups.error.type = 'loading';
    state.lookups.error.message = 'Error fetching mapping data';
  },
  toggleGeneralLookupsState: (state: IMappingState, action: PayloadAction<boolean>) => {
    if (action.payload) {
      state.lookups.relations.overlapChecked = true;
    } else {
      state.lookups.relations.overlapChecked = false;
    }
  },
  resetGeneralLookupsState: (state: IMappingState) => {
    state.lookups.relations.overlapChecked = false;
  },
  // conceptId lookups
  toggleConceptIdLookupsState: (state: IMappingState, action: PayloadAction<boolean>) => {
    if (action.payload) {
      state.lookups.identifier.overlapChecked = true;
    } else {
      state.lookups.identifier.overlapChecked = false;
    }
  },
  updateGeneralOverlapDatasetForRelations: (state: IMappingState, action: PayloadAction<any>) => {
    state.lookups.relations.overlapSelectedDataset = action.payload;
  },
  updateConceptIdOverlapDatasetForRelations: (state: IMappingState, action: PayloadAction<any>) => {
    state.lookups.identifier.overlapSelectedDataset = action.payload;
  },
  resetConceptIdLookupsState: (state: IMappingState) => {
    state.lookups.identifier.overlapChecked = false;
  },
};

const MappingReduxSlice = createSlice({
  name: 'mapping',
  initialState: mappingInitialState,
  reducers: {
    ...updateMappingReduxSlice,
    ...updateRelationLookupsSlice,
  },
});

export const {
  resetState,
  openMappingModal,
  setIsLoadingMapping,
  setSelectedProperty,
  setInitialSelectedMappingInfo,
  setDatasetColumnsForMapping,
  setHasMappingError,
  setMappingInfoLocal,
  toggleGeneralLookupsState,
  resetGeneralLookupsState,
  setLookupsIsLoading,
  setLookupsHasError,
  toggleConceptIdLookupsState,
  resetConceptIdLookupsState,
  updateGeneralOverlapDatasetForRelations,
  updateConceptIdOverlapDatasetForRelations,
  setGeneralLookupsDataSetColumns,
  setConceptIdLookupsDataSetColumns,
  setHasErrorLoadingDataSetColumns,
  initFetchingDataSetColumns,
} = MappingReduxSlice.actions;

export default MappingReduxSlice.reducer;
