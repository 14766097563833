import React from 'react';
import { Link } from 'react-router-dom-v5-compat';

import { HomeRoutes } from '../../Routing/RouteConstants';
import { TermsOfUseConstant } from './TermsOfUseConstant';

function TermsOfUseLink(props) {
  return (
    <Link
      {...props}
      to={`/${HomeRoutes.terms}`}
      className={'mh4 pointer no-underline hover-underline black-pearl'}
    >
      {TermsOfUseConstant}
    </Link>
  );
}

export default TermsOfUseLink;
