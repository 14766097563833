import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { IMappingFiltersState } from './mappingFilters.interface';
import { IQuestionFilterItem } from '@redux/Studio/QueryCreation/constants/queryCreation.interface';

export const updateMappingFiltersReduxSlice = {
  resetState: (state: IMappingFiltersState) => {
    state.showFiltersModal = initialState.showFiltersModal;
    state.showHelp = initialState.showHelp;
    state.copiedMappingFilters = initialState.copiedMappingFilters;
    state.copiedFilterPropType = initialState.copiedFilterPropType;
  },

  openFilters: (state: IMappingFiltersState) => {
    state.showFiltersModal = true;
    state.showHelp = false;
  },

  closeFilters: (state: IMappingFiltersState) => {
    state.showFiltersModal = false;
    state.showHelp = false;
  },

  copyQueryMappingFilter: (
    state: IMappingFiltersState,
    action: PayloadAction<{
      copiedMappingFilters: IQuestionFilterItem[];
      copiedFilterPropType: string;
    }>
  ) => {
    state.copiedMappingFilters = action.payload.copiedMappingFilters;
    state.copiedFilterPropType = action.payload.copiedFilterPropType;
  },

  resetCopiedQueryMappingFilter: (state: IMappingFiltersState) => {
    state.copiedMappingFilters = initialState.copiedMappingFilters;
    state.copiedFilterPropType = initialState.copiedFilterPropType;
  },

  toggleShowHelp: (state: IMappingFiltersState) => {
    state.showHelp = !state.showHelp;
  },
};

const MappingFiltersReduxSlice = createSlice({
  name: 'mappingFilters',
  initialState: initialState,
  reducers: { ...updateMappingFiltersReduxSlice },
});

export const {
  resetState,
  toggleShowHelp,
  openFilters,
  closeFilters,
  copyQueryMappingFilter,
  resetCopiedQueryMappingFilter,
} = MappingFiltersReduxSlice.actions;

export default MappingFiltersReduxSlice.reducer;
