import {
  FETCH_DOMAINS_THEN_QUERY,
  FETCH_DOMAINS_THEN_QUERY_SUCCESS,
  FETCH_DOMAINS_THEN_QUERY_ERROR,
  FETCH_DOMAINS,
  FETCH_DOMAINS_SUCCESS,
  FETCH_DOMAINS_ERROR,
  setDomains,
  setDomainsHasLoadingError,
  setDomainsIsLoading,
  FETCH_QUERY_BY_ID,
  FETCH_QUERY_BY_ID_SUCCESS,
  FETCH_QUERY_BY_ID_ERROR,
  fetchQueryById,
  setQueryById,
  setQueryByIdIsLoading,
  setQueryByIdHasLoadingError,
  SAVE_QUERY,
  SAVE_QUERY_ERROR,
  SAVE_QUERY_SUCCESS,
  saveQueryIsDoneSaving,
  setSavedQueryId,
  saveQueryIsSaving,
  saveQueryHasSavingError,
  ADD_CONCEPT,
  ADD_CONCEPT_SUCCESS,
  ADD_CONCEPT_ERROR,
  setSelectedConceptForQuery,
  GET_CALCS_LIST,
  GET_CALCS_LIST_SUCCESS,
  GET_CALCS_LIST_ERROR,
  getCalcsList,
  setCalcsList,
  setCalcsListIsLoading,
  setCalcsListHasError,
  FETCH_DOMAINS_WITH_CONCEPT_RELATIONS,
  setDomainsWithConceptRelations,
  setDomainsWithConceptRelationsHasLoadingError,
  setDomainsWithConceptRelationsIsLoading,
  FETCH_DOMAINS_WITH_CONCEPT_RELATIONS_SUCCESS,
  FETCH_DOMAINS_WITH_CONCEPT_RELATIONS_ERROR,
} from './actions';

import url from '../../../api-config';
import { apiRequest } from '../../Api/actions';
import { updateErrorMessage } from '../../ErrorModal/error-modal-redux-slice';

export const fetchData =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    switch (action.type) {
      case FETCH_DOMAINS_THEN_QUERY:
        dispatch(setDomains([]));
        dispatch(setDomainsHasLoadingError(false));
        dispatch(setDomainsIsLoading(true));
        dispatch(
          apiRequest(
            'GET',
            `${url.domain}/all`,
            {},
            FETCH_DOMAINS_THEN_QUERY_SUCCESS,
            FETCH_DOMAINS_THEN_QUERY_ERROR,
            { queryId: action.payload.queryId }
          )
        );
        break;

      case FETCH_DOMAINS:
        dispatch(setDomains([]));
        dispatch(setDomainsHasLoadingError(false));
        dispatch(setDomainsIsLoading(true));
        dispatch(
          apiRequest('GET', `${url.domain}/all`, {}, FETCH_DOMAINS_SUCCESS, FETCH_DOMAINS_ERROR)
        );
        break;

      case FETCH_DOMAINS_WITH_CONCEPT_RELATIONS:
        dispatch(setDomainsWithConceptRelations([]));
        dispatch(setDomainsWithConceptRelationsHasLoadingError(false));
        dispatch(setDomainsWithConceptRelationsIsLoading(true));
        dispatch(
          apiRequest(
            'GET',
            `${url.domain}/${action?.payload?.domainId}/concept/directrelation?conceptUri=${action?.payload?.conceptURI}`,
            {},
            FETCH_DOMAINS_WITH_CONCEPT_RELATIONS_SUCCESS,
            FETCH_DOMAINS_WITH_CONCEPT_RELATIONS_ERROR
          )
        );
        break;

      case FETCH_QUERY_BY_ID:
        dispatch(setQueryByIdHasLoadingError(false, -1));
        dispatch(setQueryByIdIsLoading(true));
        dispatch(setQueryById({}));
        dispatch(
          apiRequest(
            'GET',
            `${url.queries}/${action.id}`,
            action.data,
            FETCH_QUERY_BY_ID_SUCCESS,
            FETCH_QUERY_BY_ID_ERROR
          )
        );
        break;

      case SAVE_QUERY:
        dispatch(saveQueryIsSaving(true));
        dispatch(saveQueryHasSavingError(false));
        dispatch(saveQueryIsDoneSaving(false));
        dispatch(
          apiRequest(
            'PUT',
            `${url.queries}/${action.data.id}`,
            action.data,
            SAVE_QUERY_SUCCESS,
            SAVE_QUERY_ERROR,
            { keepSelectedConcept: action.keepSelectedConcept }
          )
        );
        break;

      case ADD_CONCEPT:
        // API is same as SAVE_QUERY above
        dispatch(saveQueryIsSaving(true));
        dispatch(saveQueryHasSavingError(false));
        dispatch(saveQueryIsDoneSaving(false));
        dispatch(
          apiRequest(
            'PUT',
            `${url.queries}/${action.data.id}`,
            action.data,
            ADD_CONCEPT_SUCCESS,
            ADD_CONCEPT_ERROR,
            { newConceptId: action.newConceptId, newConceptColor: action.newConceptColor }
          )
        );
        break;

      case GET_CALCS_LIST:
        dispatch(setCalcsList([]));
        dispatch(setCalcsListIsLoading(true));
        dispatch(setCalcsListHasError(false));
        dispatch(
          apiRequest(
            'GET',
            `${url.queries}/${action.payload}/calculations`,
            {},
            GET_CALCS_LIST_SUCCESS,
            GET_CALCS_LIST_ERROR
          )
        );
        break;

      default:
        break;
    }
  };

export const processCollections =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    switch (action.type) {
      case FETCH_DOMAINS_SUCCESS:
        dispatch(setDomains(action.payload));
        dispatch(setDomainsIsLoading(false));
        break;

      case FETCH_DOMAINS_WITH_CONCEPT_RELATIONS_SUCCESS:
        dispatch(setDomainsWithConceptRelations(action.payload));
        dispatch(setDomainsWithConceptRelationsIsLoading(false));
        break;

      case FETCH_DOMAINS_THEN_QUERY_SUCCESS:
        dispatch(setDomains(action.payload));
        dispatch(setDomainsIsLoading(false));
        dispatch(fetchQueryById(action.props.queryId));
        break;

      case FETCH_DOMAINS_ERROR:
      case FETCH_DOMAINS_THEN_QUERY_ERROR:
        dispatch(setDomainsHasLoadingError(true));
        dispatch(setDomainsIsLoading(false));
        break;

      case FETCH_DOMAINS_WITH_CONCEPT_RELATIONS_ERROR:
        dispatch(setDomainsWithConceptRelationsHasLoadingError(true));
        dispatch(setDomainsWithConceptRelationsIsLoading(false));
        break;

      case FETCH_QUERY_BY_ID_SUCCESS:
        dispatch(setQueryByIdIsLoading(false));
        dispatch(setQueryById(action.payload));
        break;

      case FETCH_QUERY_BY_ID_ERROR:
        dispatch(setQueryByIdIsLoading(false));
        dispatch(setQueryByIdHasLoadingError(true, action.payload));
        break;

      case SAVE_QUERY_SUCCESS:
        dispatch(saveQueryIsSaving(false));
        dispatch(saveQueryIsDoneSaving(true));
        dispatch(setSavedQueryId(action.payload, action.props.keepSelectedConcept));
        // fetch calc list so that if there are missing props from calcs, the error will show
        dispatch(getCalcsList(action.payload.id));
        break;

      case SAVE_QUERY_ERROR:
        dispatch(updateErrorMessage(action.payload));
        dispatch(saveQueryIsSaving(false));
        dispatch(saveQueryIsDoneSaving(true));
        break;

      case ADD_CONCEPT_ERROR:
        console.warn('ADD_CONCEPT_ERROR:', action);
        dispatch(saveQueryIsSaving(false));
        dispatch(saveQueryHasSavingError(true));
        dispatch(saveQueryIsDoneSaving(true));
        break;

      case ADD_CONCEPT_SUCCESS:
        dispatch(saveQueryIsSaving(false));
        dispatch(saveQueryIsDoneSaving(true));
        dispatch(setSavedQueryId(action.payload));

        dispatch(
          setSelectedConceptForQuery(
            action.props.newConceptId,
            action.payload.definition[action.props.newConceptId],
            action.props.newConceptColor
          )
        );

        // fetch calc list so that if there are missing props from calcs, the error will show
        dispatch(getCalcsList(action.payload.id));
        break;

      case GET_CALCS_LIST_SUCCESS:
        dispatch(setCalcsListIsLoading(false));
        dispatch(setCalcsList(action.payload));
        break;

      case GET_CALCS_LIST_ERROR:
        dispatch(setCalcsListIsLoading(false));
        dispatch(setCalcsListHasError(true));
        break;

      default:
        break;
    }
  };

export const queryCreationModel = [fetchData, processCollections];
