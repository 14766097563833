import { Navigate, useParams } from 'react-router-dom-v5-compat';
import { Suspense } from 'react';

import { IRoutes } from '@3.0/Layouts/TabbedLayout/utils/constants/routes.interface';
import { StudioRoutes } from 'Routing/RouteConstants';
import ErrorPage from '@components/ErrorBoundry/ErrorPage';
import { LoadingIndicator } from '@components/LoadingIndicatorMaterial';

const EditQuestionRedirect = () => {
  const params = useParams();
  return <Navigate to={`${StudioRoutes.base}/${StudioRoutes.questions.base}/${params.queryId}`} />;
};

export const editQuestionRoutes: IRoutes[] = [
  {
    routeItem: {
      path: `${StudioRoutes.questions.base}/create-question${StudioRoutes.createQuestion.basePathVariables}/*`,
      element: <EditQuestionRedirect />,
      errorElement: <ErrorPage />,
    },
    permissionRef: StudioRoutes.createQuestion.permission,
    featureFlag: true,
  },

  {
    routeItem: {
      path: `${StudioRoutes.questions.base}/${StudioRoutes.createQuestion.base}${StudioRoutes.createQuestion.basePathVariables}/*`,
      id: 'editQueryView',
      errorElement: <ErrorPage />,
      children: [
        {
          path: '',
          id: 'editQueryViewHome',
          errorElement: <ErrorPage />,
          async lazy() {
            const EditQuery = (await import('@components/Studio/QueryCreationTwo/EditQuery'))
              .default;

            return {
              element: (
                <Suspense fallback={<LoadingIndicator isLoading />}>
                  <EditQuery />
                </Suspense>
              ),
            };
          },
        },
        {
          path: StudioRoutes.editQuestionMapping.base,
          id: 'editQuestionMappingView',
          errorElement: <ErrorPage />,
          async lazy() {
            const EditQuestionMappingView = (
              await import('Modules/Studio/Mappings/EditQuestionMappingView')
            ).default;

            const { fetchMappingListByQueryId } = await import(
              'Modules/Studio/Mappings/routing/fetchMappingListByQueryId.loader'
            );

            return {
              element: (
                <Suspense fallback={<LoadingIndicator isLoading />}>
                  <EditQuestionMappingView />
                </Suspense>
              ),
              loader: fetchMappingListByQueryId,
            };
          },
        },
        {
          path: StudioRoutes.editQuestionPreview.base,
          errorElement: <ErrorPage />,
          async lazy() {
            const QuestionPreview = (
              await import('Modules/Studio/Questions/EditQuestion/QuestionPreview/QuestionPreview')
            ).default;

            return {
              element: (
                <Suspense fallback={<LoadingIndicator isLoading />}>
                  <QuestionPreview />
                </Suspense>
              ),
            };
          },
        },

        {
          path: StudioRoutes.editQuestionCards.base,
          errorElement: <ErrorPage />,
          async lazy() {
            const CardsHOC = (await import('Modules/Studio/Questions/Cards/CardsHOC')).default;

            return {
              element: (
                <Suspense fallback={<LoadingIndicator isLoading />}>
                  <CardsHOC />
                </Suspense>
              ),
            };
          },
        },
      ],
      async lazy() {
        const EditQueryLayout = (
          await import('Modules/Studio/Questions/EditQuestion/Layout/EditQueryLayout')
        ).default;

        return {
          element: (
            <Suspense fallback={<LoadingIndicator isLoading />}>
              <EditQueryLayout />
            </Suspense>
          ),
        };
      },
    },
    permissionRef: StudioRoutes.createQuestion.permission,
    featureFlag: true,
  },
];
