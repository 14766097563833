// DATA SOURCES
export const SET_DATA_SOURCES = '[Studio DataSources] Set DataSources';
export const setDataSources = (types) => ({
  type: SET_DATA_SOURCES,
  payload: types,
});

export const FILTER_DATA_SOURCES_BY_NAME = 'FILTER_DATA_SOURCES_BY_NAME';
export const filterDataSourcesByName = (searchStr) => ({
  type: FILTER_DATA_SOURCES_BY_NAME,
  payload: searchStr,
});

export const FILTER_DATA_SOURCES_BY_TYPE = 'FILTER_DATA_SOURCES_BY_TYPE';
export const filterDataSourcesByType = (type) => ({
  type: FILTER_DATA_SOURCES_BY_TYPE,
  payload: type,
});

export const SET_DATA_SOURCES_STANDALONE = '[Studio DataSources] Set DataSources Standalone';
export const setDataSourcesStandAlone = (types) => ({
  type: SET_DATA_SOURCES_STANDALONE,
  payload: types,
});

export const FETCH_DATA_SOURCES = '[Studio DataSources] Fetch DataSources';
export const FETCH_DATA_SOURCES_SUCCESS = '[Studio DataSources] Fetch DataSources Success';
export const FETCH_DATA_SOURCES_ERROR = '[Studio DataSources] Fetch DataSources Error';
export const fetchDataSources = (id) => ({
  type: FETCH_DATA_SOURCES,
  payload: id,
});

export const FETCH_DATA_SOURCES_STANDALONE = '[Studio DataSources] Fetch DataSources StandAlone';
export const FETCH_DATA_SOURCES_STANDALONE_SUCCESS =
  '[Studio DataSources] Fetch DataSources StandAlone Success';
export const FETCH_DATA_SOURCES_STANDALONE_ERROR =
  '[Studio DataSources] Fetch DataSources StandAlone Error';
export const fetchDataSourcesStandAlone = () => ({
  type: FETCH_DATA_SOURCES_STANDALONE,
  payload: {},
});

export const FILTER_DATA_SOURCES_STANDALONE =
  '[Studio DataSources] Filter DataSources List StandAlone';
export const filterDataSourcesStandAlone = (str) => ({
  type: FILTER_DATA_SOURCES_STANDALONE,
  payload: str,
});

export const SET_ERROR_LOADING_DATA_SOURCES_STANDALONE =
  '[Studio DataSources] Set Error Loading DataSources List StandAlone';
export const setErrorLoadingDataSourcesStandAlone = (status, error) => ({
  type: SET_ERROR_LOADING_DATA_SOURCES_STANDALONE,
  payload: { status, error },
});

export const SET_IS_LOADING = '[Studio DataSources] Set Is Loading';
export const setIsLoading = (status) => ({
  type: SET_IS_LOADING,
  payload: status,
});

export const SET_IS_LOADING_DATA_SOURCE_STANDALONE =
  '[Studio DataSources] Set Is Loading Data Source StandAlone';
export const setIsLoadingDataSourceStandAlone = (status) => ({
  type: SET_IS_LOADING_DATA_SOURCE_STANDALONE,
  payload: status,
});

export const RESET_SELECTED_DATA_SOURCE = '[Studio DataSources] Reset Selected DataSource';
export const resetSelectedDataSource = () => ({
  type: RESET_SELECTED_DATA_SOURCE,
});

export const SET_ERROR_LOADING_DATA_SOURCES = '[Studio DataSources] Set Error Loading DataSources';
export const setErrorLoadingDataSources = (status, error) => ({
  type: SET_ERROR_LOADING_DATA_SOURCES,
  payload: { status, error },
});

// DATA SOURCE TYPES (i.e postgres, rabbit, etc)
export const SET_DATA_SOURCE_TYPES = '[Studio DataSources] Set DataSource Types';
export const setDataSourceTypes = (types) => ({
  type: SET_DATA_SOURCE_TYPES,
  payload: types,
});

export const FETCH_DATA_SOURCE_TYPES = '[Studio DataSources] Fetch DataSource Types';
export const FETCH_DATA_SOURCE_TYPES_SUCCESS =
  '[Studio DataSources] Fetch DataSource Types Success';
export const FETCH_DATA_SOURCE_TYPES_ERROR = '[Studio DataSources] Fetch DataSource Types Error';
export const fetchDataSourceTypes = () => ({ type: FETCH_DATA_SOURCE_TYPES });

export const SET_DATA_SOURCE_TYPES_IS_LOADING =
  '[Studio DataSources] Set Data Source Types Is Loading';
export const setTypesIsLoading = (status) => ({
  type: SET_DATA_SOURCE_TYPES_IS_LOADING,
  payload: status,
});

export const SET_ERROR_LOADING_DATA_SOURCE_TYPES =
  '[Studio DataSources] Set Error Loading DataSource Types';
export const setErrorLoadingDataSourceTypes = (status) => ({
  type: SET_ERROR_LOADING_DATA_SOURCE_TYPES,
  payload: status,
});

// TABLES
export const SET_DATA_SOURCE_TABLES = '[Studio DataSources] Set DataSource Tables';
export const setDataSourceTables = (tables) => ({
  type: SET_DATA_SOURCE_TABLES,
  payload: tables,
});

export const SET_ACTIVE_DATA_SOURCE_TABLE =
  '[Studio DataSources] Set DataSources active table name';
export const setActiveDataSourceTable = (tableName) => ({
  type: SET_ACTIVE_DATA_SOURCE_TABLE,
  payload: tableName,
});

export const SET_ACTIVE_DATA_SOURCE_TABLE_COUNTS =
  '[Studio DataSources] Set DataSources active table name counts';
export const setActiveDataSourceTableColumnCounts = (counts) => ({
  type: SET_ACTIVE_DATA_SOURCE_TABLE_COUNTS,
  payload: counts,
});

export const FETCH_DATA_SOURCE_TABLES = '[Studio DataSources] Fetch DataSource Tables';
export const FETCH_DATA_SOURCE_TABLES_SUCCESS =
  '[Studio DataSources] Fetch DataSource Tables Success';
export const FETCH_DATA_SOURCE_TABLES_ERROR = '[Studio DataSources] Fetch DataSource Tables Error';
export const fetchDataSourceTables = (
  sourceId,
  solutionId,
  queryId,
  pageLimit = '10',
  pageNumber = 1,
  showLoad = true,
  term = ''
) => ({
  type: FETCH_DATA_SOURCE_TABLES,
  payload: {
    sourceId,
    solutionId,
    queryId,
    pageNumber,
    term,
    pageLimit,
    showLoad,
  },
});

export const SET_TABLES_IS_LOADING = '[Studio DataSources] Set Tables Is Loading';
export const setTablesIsLoading = (status) => ({
  type: SET_TABLES_IS_LOADING,
  payload: status,
});

export const SET_ERROR_LOADING_DATA_SOURCE_TABLES =
  '[Studio DataSources] Set Error Loading DataSource Tables';
export const setErrorLoadingDataSourceTables = (status) => ({
  type: SET_ERROR_LOADING_DATA_SOURCE_TABLES,
  payload: status,
});

// COLUMNS
export const SET_COLUMNS = '[Studio DataSources] Set DataSource Table Columns';
export const setColumns = (tables) => ({ type: SET_COLUMNS, payload: tables });

export const FETCH_COLUMNS = '[Studio DataSources] Fetch DataSource Table Columns';
export const FETCH_COLUMNS_SUCCESS = '[Studio DataSources] Fetch DataSource Table Columns Success';
export const FETCH_COLUMNS_ERROR = '[Studio DataSources] Fetch DataSource Table  Columns Error';
export const fetchDataSourceTableColumns = (
  sourceId,
  solutionId,
  queryId,
  tableName,
  pageLimit = null,
  pageNumber = 1,
  term = ''
) => ({
  type: FETCH_COLUMNS,
  payload: {
    sourceId,
    tableName,
    solutionId,
    queryId,
    pageNumber,
    pageLimit,
    term,
  },
});

export const SET_COLUMNS_IS_LOADING = '[Studio DataSources] Set Table Columns Is Loading';
export const setColumnsIsLoading = (status) => ({
  type: SET_COLUMNS_IS_LOADING,
  payload: status,
});

export const SET_ERROR_LOADING_COLUMNS =
  '[Studio DataSources] Set Error Loading DataSource Table Columns';
export const setErrorLoadingColumns = (status, errorCode) => ({
  type: SET_ERROR_LOADING_COLUMNS,
  payload: { status, errorCode },
});

export const UPDATE_DATA_SOURCE_TABLES_PAGINATION_PAGE_LIMIT =
  'UPDATE_DATA_SOURCE_TABLES_PAGINATION_PAGE_LIMIT';
export const updateDataSourceTablesPaginationPageLimit = (pageLimit) => ({
  type: UPDATE_DATA_SOURCE_TABLES_PAGINATION_PAGE_LIMIT,
  payload: pageLimit,
});

export const UPDATE_DATA_SOURCE_TABLES_PAGINATION_CURRENT_PAGE =
  'UPDATE_DATA_SOURCE_TABLES_PAGINATION_CURRENT_PAGE';
export const updateDataSourceTablesPaginationCurrentPage = (currentPage) => ({
  type: UPDATE_DATA_SOURCE_TABLES_PAGINATION_CURRENT_PAGE,
  payload: currentPage,
});

export const UPDATE_DATA_SOURCE_TABLES_PAGINATION_SEARCH_TERM =
  'UPDATE_DATA_SOURCE_TABLES_PAGINATION_SEARCH_TERM';
export const updateDataSourceTablesPaginationSearchTerm = (searchTerm) => ({
  type: UPDATE_DATA_SOURCE_TABLES_PAGINATION_SEARCH_TERM,
  payload: searchTerm,
});

export const RESET_DATA_SOURCE_TABLES_PAGINATION = 'RESET_DATA_SOURCE_TABLES_PAGINATION';
export const resetDataSourceTablesPagination = () => ({
  type: RESET_DATA_SOURCE_TABLES_PAGINATION,
});

export const TOGGLE_DATA_SOURCE_MODAL = 'TOGGLE_DATA_SOURCE_MODAL';
export const toggleDataSourceModal = (status) => ({
  type: TOGGLE_DATA_SOURCE_MODAL,
  payload: status,
});

export const SET_SELECTED_DATA_SOURCE_FOR_MODAL = 'SET_SELECTED_DATA_SOURCE_FOR_MODAL';
export const setSelectedDataSourceForModal = (dataSource) => ({
  type: SET_SELECTED_DATA_SOURCE_FOR_MODAL,
  payload: dataSource,
});

export const CLOSE_DATA_SOURCE_MODAL = 'CLOSE_DATA_SOURCE_MODAL';
export const closeDataSourceModal = () => ({
  type: CLOSE_DATA_SOURCE_MODAL,
});
