import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IPreviewQuestion {
  showFiltersForm: boolean;
  page: number;
  rowsPerPage: number;
}

export const initialState: IPreviewQuestion = {
  showFiltersForm: false,
  page: 0,
  rowsPerPage: 20,
};

export const questionPreviewReducer = {
  resetState: (state: IPreviewQuestion) => {
    state.showFiltersForm = initialState.showFiltersForm;
    state.page = initialState.page;
    state.rowsPerPage = initialState.rowsPerPage;
  },

  openShowFiltersForm: (state: IPreviewQuestion) => {
    state.showFiltersForm = true;
  },

  closeShowFiltersForm: (state: IPreviewQuestion) => {
    state.showFiltersForm = false;
  },

  onPageChange: (state: IPreviewQuestion, action: PayloadAction<{ newPage: number }>) => {
    state.page = action.payload.newPage;
  },

  onRowsPerPageChange: (
    state: IPreviewQuestion,
    action: PayloadAction<{ newRowsPerPage: number }>
  ) => {
    state.page = initialState.page;
    state.rowsPerPage = action.payload.newRowsPerPage;
  },
};

export const questionPreviewSlice = createSlice({
  name: 'previewQuestion',
  initialState: initialState,
  reducers: questionPreviewReducer,
});

export const {
  openShowFiltersForm,
  closeShowFiltersForm,
  resetState,
  onPageChange,
  onRowsPerPageChange,
} = questionPreviewSlice.actions;

export default questionPreviewSlice.reducer;
