import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IDataSourceState,
  dataSourceTablesPaginationInitialState,
  datasourcesInitialState,
  studioInitialPath,
  tableColumnsInitialState,
} from './initialState';
import { allCSVDataSourceTypes } from '../components/Datasets/IngestSettings/components/IncrementalIngestionSettings/utilities/IncrementalIngestionsSettings.constants';

export const updateDatasourcesReduxSlice = {
  setIsLoadingDatasources: (state: IDataSourceState, action: PayloadAction<boolean>) => {
    state.isLoading = action.payload;
  },

  toggleDataSourceModal: (state: IDataSourceState, action: PayloadAction<boolean>) => {
    state.isDataSourceModalOpen = action.payload;
  },

  setIsDataSourceTypesLoading: (state: IDataSourceState, action: PayloadAction<boolean>) => {
    state.types.isLoading = action.payload;
  },

  setIsDataSourceTypesError: (state: IDataSourceState, action: PayloadAction<boolean>) => {
    state.types.hasError = action.payload;
  },

  closeDataSourceModal: (state: IDataSourceState) => {
    state.isDataSourceModalOpen = false;
    state.selectedDataSourceForModal = null;
  },

  setErrorLoadingDataSources: (
    state: IDataSourceState,
    action: PayloadAction<{
      hasLoadingError: boolean;
      errorCode: number;
    }>
  ) => {
    state.isLoading = false;
    state.hasLoadingError = action.payload.hasLoadingError;
    state.errorCode = action.payload.errorCode;
  },

  setDataSources: (
    state: IDataSourceState,
    action: PayloadAction<{
      [key: string]: any[];
    }>
  ) => {
    state.isLoading = false;
    const datasourceList = Object.keys(action.payload).reduce((acc, type: string) => {
      return {
        ...acc,
        [type]: action.payload[type].map((ds: any) => ({
          ...ds,
          // init empty object - later used in selectedDatasource
          userName: '',
          password: '',
          connectionUrl: '',
          props: {},
        })),
      };
    }, {});

    state.datasourceList = datasourceList;
    state.filteredList = datasourceList;
    state.isDataSourcesEmpty = Object.keys(datasourceList).length === 0;
  },

  setDataSourceTypes: (
    state: IDataSourceState,
    action: PayloadAction<{
      dataSourceTypesList: any[];
    }>
  ) => {
    state.types.isLoading = false;

    state.types.typesList = action.payload.dataSourceTypesList;
    state.types.csvTypeIds = action.payload.dataSourceTypesList
      .filter((type: any) => allCSVDataSourceTypes.includes(type.id))
      .map((item: any) => item.id);
    state.types.typesMap = action.payload.dataSourceTypesList.reduce(
      (acc, curr) => ({ ...acc, [curr.id]: curr }),
      {}
    );
  },

  resetDataSourceTablesPagination: (state: IDataSourceState) => {
    state.tableInfo.tablesPagination = dataSourceTablesPaginationInitialState;
  },

  initFetchDataSourceById: (state: IDataSourceState) => {
    state.selectedDataSourceForModal = null;
    state.isDataSourceModalOpen = false;
    state.isFetchingDataSourceById = true;
    state.hasErrorFetchingDataSourceById = false;
  },

  setHasErrorFetchDataSourceById: (state: IDataSourceState) => {
    state.isFetchingDataSourceById = false;
    state.hasErrorFetchingDataSourceById = true;
  },

  setSelectedDataSourceForModal: (state: IDataSourceState, action: PayloadAction<null | any>) => {
    state.selectedDataSourceForModal = action.payload;
    state.isDataSourceModalOpen = true;
    state.isFetchingDataSourceById = false;
  },

  filterDataSourcesByName: (state: IDataSourceState, action: PayloadAction<string>) => {
    let filteredDatasources = state.datasourceList;
    const searchTerm = action.payload.toLowerCase();

    if (searchTerm !== '') {
      filteredDatasources = Object.keys(state.datasourceList).reduce((acc, type) => {
        return {
          ...acc,
          [type]: state.datasourceList[type].filter(
            (item: any) => item.name.toLowerCase().indexOf(searchTerm) > -1
          ),
        };
      }, {});
    }
    state.filteredList = filteredDatasources;
  },

  filterDataSourcesByType: (state: IDataSourceState, action: PayloadAction<number>) => {
    let filteredDatasources = state.datasourceList;

    if (action.payload !== 0) {
      filteredDatasources = Object.keys(state.datasourceList).reduce((acc, type) => {
        return {
          ...acc,
          [type]: state.datasourceList[type].filter(
            (item: any) => item.dataSourceType === action.payload
          ),
        };
      }, {});
    }
    state.filteredList = filteredDatasources;
  },

  setTablesIsLoading: (state: IDataSourceState, action: PayloadAction<boolean>) => {
    state.tableInfo.isLoading = action.payload;
  },

  setErrorLoadingTables: (state: IDataSourceState, action: PayloadAction<boolean>) => {
    state.tableInfo.hasError = action.payload;
  },

  setDataSourceTables: (
    state: IDataSourceState,
    action: PayloadAction<{
      [key: string]: any | any[];
    }>
  ) => {
    let newActiveDataSourceTotalCols = state.activeDataSourceTotalCols;
    let newActiveDataSourceSelectedColCount = state.activeDataSourceSelectedColCount;

    const indexedTables = action.payload.data.reduce(
      (dataSourceTables: { [key: string]: any }, dataSourceTable: any) => {
        if (dataSourceTable.tableName === state.tableInfo.activeTableName) {
          newActiveDataSourceTotalCols = dataSourceTable.total;
          newActiveDataSourceSelectedColCount = dataSourceTable.selectedCount;
        }
        return {
          ...dataSourceTables,
          [dataSourceTable.tableName]: dataSourceTable,
        };
      },
      {}
    );

    state.tableInfo.isLoading = false;
    state.tableInfo.tablesRaw = action.payload.data;
    state.tableInfo.tablesIndexedList = indexedTables;
    state.activeDataSourceTotalCols = newActiveDataSourceTotalCols;
    state.tableInfo.totalTables = action.payload.totalCount as unknown as number;
    state.activeDataSourceSelectedColCount = newActiveDataSourceSelectedColCount;
  },

  setActiveDataSourceTable: (
    state: IDataSourceState,
    action: PayloadAction<{ tableName: string | null; resetErrors: boolean }>
  ) => {
    state.tableInfo.activeTableName = action.payload.tableName;
    if (action.payload.resetErrors) {
      // reset state
      state.tableInfo.isLoading = false;
      state.tableInfo.hasError = false;
    }
  },

  setActiveDataSourceTableColumnCounts: (
    state: IDataSourceState,
    action: PayloadAction<{
      total: number;
      selectedCount: number;
    }>
  ) => {
    state.activeDataSourceTotalCols = action.payload.total;
    state.activeDataSourceSelectedColCount = action.payload.selectedCount;
  },

  updateDataSourceTablesPaginationCurrentPage: (
    state: IDataSourceState,
    action: PayloadAction<number>
  ) => {
    state.tableInfo.tablesPagination.currentPage = action.payload;
  },

  updateDataSourceTablesPaginationPageLimit: (
    state: IDataSourceState,
    action: PayloadAction<number>
  ) => {
    state.tableInfo.tablesPagination.pageLimit = action.payload;
  },

  updateDataSourceTablesPaginationSearchTerm: (
    state: IDataSourceState,
    action: PayloadAction<string>
  ) => {
    state.tableInfo.tablesPagination.searchTerm = action.payload;
  },

  initLoadingColumns: (state: IDataSourceState) => {
    // reset to initial
    state.tableColumns.columnInfo.data = tableColumnsInitialState.columnInfo.data;
    state.tableColumns.columnInfo.searchCount = tableColumnsInitialState.columnInfo.searchCount;
    state.tableColumns.columnInfo.selectedCount = tableColumnsInitialState.columnInfo.selectedCount;
    state.tableColumns.columnInfo.totalCount = tableColumnsInitialState.columnInfo.totalCount;
    state.tableColumns.columnList = tableColumnsInitialState.columnList;

    state.activeDataSourceSelectedColCount = 0;
    state.tableColumns.isLoading = true;
    state.tableColumns.hasError = false;
    state.tableColumns.errorCode = -1;
  },

  setErrorLoadingColumns: (
    state: IDataSourceState,
    action: PayloadAction<{
      status: boolean;
      errorCode: number;
    }>
  ) => {
    state.tableColumns.isLoading = false;
    state.tableColumns.hasError = action.payload.status;
    state.tableColumns.errorCode = action.payload.errorCode;
  },

  setColumns: (
    state: IDataSourceState,
    action: PayloadAction<{
      data: any;
      searchCount: number;
      selectedCount: number;
      totalCount: number;
    }>
  ) => {
    state.tableColumns.isLoading = false;
    state.tableColumns.columnInfo = action.payload;
    state.tableColumns.columnList = action.payload.data;
    state.activeDataSourceSelectedColCount = action.payload.selectedCount;
  },

  resetPreviousPath: (state: IDataSourceState) => {
    state.previousPath = studioInitialPath;
  },

  setPreviousPath: (state: IDataSourceState, action: PayloadAction<string>) => {
    state.previousPath = action.payload;
  },
};

const DatasourcesReduxSlice = createSlice({
  name: 'datasources',
  initialState: datasourcesInitialState,
  reducers: updateDatasourcesReduxSlice,
});

export const {
  // table columns
  initLoadingColumns,
  setColumns,
  setErrorLoadingColumns,

  // datasources
  setIsLoadingDatasources,
  setDataSources,
  setDataSourceTypes,
  filterDataSourcesByType,
  filterDataSourcesByName,
  setIsDataSourceTypesError,
  setErrorLoadingDataSources,
  setIsDataSourceTypesLoading,

  // route
  setPreviousPath,
  resetPreviousPath,

  // tables
  setTablesIsLoading,
  setDataSourceTables,
  setErrorLoadingTables,
  setActiveDataSourceTable,
  updateDataSourceTablesPaginationPageLimit,
  updateDataSourceTablesPaginationSearchTerm,
  updateDataSourceTablesPaginationCurrentPage,
  resetDataSourceTablesPagination,
  setActiveDataSourceTableColumnCounts,

  // ds modal
  toggleDataSourceModal,
  closeDataSourceModal,
  setSelectedDataSourceForModal,
  initFetchDataSourceById,
  setHasErrorFetchDataSourceById,
} = DatasourcesReduxSlice.actions;

export default DatasourcesReduxSlice.reducer;
