import { AggregateItemType } from '@components/Studio/QueryCreationTwo/Props/modals/AggregatesModal/interfaces/Aggregates.interface';
import { IQuestionFilterItem } from '../constants/queryCreation.interface';

export type aggrDepType = {
  [key in AggregateItemType]: boolean;
};

export type filterDepsType = Record<string, Record<string, Record<string, boolean>>>;

const getBase = (acc: filterDepsType, filter: IQuestionFilterItem) => {
  const concept = filter?.elementProps?.concept || '';
  const prop = filter?.elementProps?.property || '';
  const aggrType = filter?.elementProps?.aggrType || '';

  const propertyDep = filter.elementType === 'PROP' ? {} : { [aggrType]: true };
  const existingPropertyDeps = acc?.[concept]?.[prop] || {};

  return {
    ...acc,
    [concept]: {
      ...(acc?.[concept] || {}),
      [prop]: {
        ...existingPropertyDeps,
        ...propertyDep,
      },
    },
  };
};

const recurseChildren = (filters: IQuestionFilterItem[], deps: filterDepsType): filterDepsType => {
  return filters.reduce((filtersAcc, filter) => {
    if (filter.children && filter.children.length > 0) {
      return {
        ...filtersAcc,
        ...recurseChildren(filter.children, filtersAcc),
      };
    } else {
      return getBase(filtersAcc, filter);
    }
  }, deps);
};

const getFilterPropDeps = (questionWideFilters: IQuestionFilterItem[]): filterDepsType => {
  try {
    let deps = questionWideFilters.reduce((acc: filterDepsType, filter: IQuestionFilterItem) => {
      if (filter.children && filter.children.length > 0) {
        return {
          ...acc,
          ...recurseChildren(filter.children || [], acc),
        };
      } else {
        return getBase(acc, filter);
      }
    }, {});

    return deps;
  } catch (e) {
    console.error(e);
    return {};
  }
};

export default getFilterPropDeps;
