import React from 'react';

import logo from '../../assets/images/logo-light.png';
import KobaiEngine from '../../assets/images/Kobai-Engine.png';
import { Animated } from '../../components/Animated';
import TermsOfUseLink from '../../components/TermsOfUse/TermsOfUseLink';
import '../../Modules/PageNotFound/PageNotFound.css';

function NotSetup() {
  return (
    <Animated
      animationInDuration={500}
      animationOutDuration={300}
      className={'home w-100 vh-100 flex-column items-center bg-fafafa Roboto flex'}
    >
      <section
        test-id="page-not-found-container"
        className={'page-not-found-container bg-white mt5 flex'}
      >
        <article
          className={
            'page-not-found-messaging pa4 relative bg-black-pearl overflow-hidden flex flex-column items-center'
          }
        >
          {/* LOGO */}
          <header className={'w-100 flex flex-column items-center pb4 relative z-1'}>
            <img src={logo} alt="Kobai" width="150" />
          </header>

          {/* LOGIN SIDEBAR */}
          <Animated
            animationInDuration={1000}
            animationOutDuration={1000}
            animateOnMount={false}
            className={'flex flex-column items-center z-1'}
          >
            <span className={'w-100 white db tc fw3 pt4 pb1 f2 relative z-1'}>NOT CONFIGURED</span>
            <span style={{ width: '415px' }} className={'w-100 white db tc f6 relative z-1'}>
              Kobai has not been configured or has been incorrectly configured. Please contact your
              administrator to setup and configure Kobai.
            </span>
            <img
              src={KobaiEngine}
              alt="Kobai Engine"
              width="270"
              className="absolute z-1 bottom-2 pb2"
            />
          </Animated>

          {/* GRADIENT BACKGROUND */}
          <div className="page-not-found-messaging_bg absolute z-0 left-0 top-0"></div>
        </article>
      </section>

      {/* FOOTER */}
      <Animated
        animationInDelay={1000}
        animationInDuration={1200}
        animationOutDuration={1000}
        className={'w-100 flex justify-center mt4 fw1'}
        animateOnMount={true}
      >
        <span>© {new Date().getFullYear()} Kobai Inc.</span>
        <TermsOfUseLink test-id="page-not-found-terms-of-use-link" />
      </Animated>
    </Animated>
  );
}

export default NotSetup;
