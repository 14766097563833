import { getQueryGraphOptions } from './utils/QueryGraphUtils';

import {
  // used when EditQuery component unmounts
  RESET_QUERY_STATE,
  SET_DOMAINS,
  SET_DOMAINS_HAS_LOADING_ERROR,
  SET_DOMAINS_IS_LOADING,
  SET_QUERY_BY_ID,
  SET_QUERY_BY_ID_IS_LOADING,
  SET_QUERY_BY_ID_HAS_LOADING_ERROR,
  SET_SELECTED_CONCEPT_FOR_QUERY,
  CLEAR_SELECTED_CONCEPT,
  UPDATE_SELECTED_CONCEPT_PROPERTIES,
  SAVE_QUERY_IS_DONE_SAVING,
  SAVE_QUERY_IS_SAVING,
  SAVE_QUERY_HAS_SAVING_ERROR,
  INIT_SAVING_QUERY,
  SET_SAVED_QUERY_ID,
  SET_CALCS_LIST,
  SET_CALCS_LIST_IS_LOADING,
  SET_CALCS_LIST_HAS_ERROR,
  TOGGLE_MODEL_GRAPH,
  TOGGLE_RUNTIME_SETTINGS_MODAL,
  SET_DOMAINS_WITH_CONCEPT_RELATIONS,
  SET_DOMAINS_WITH_CONCEPT_RELATIONS_HAS_LOADING_ERROR,
  SET_DOMAINS_WITH_CONCEPT_RELATIONS_IS_LOADING,
  IS_PROPERTY_ENTITY,
  IS_CONCEPT_SETTINGS_MODAL_OPEN,

  // used for sub-queries
  UPDATE_QUERY_WITH_ADDED_SUB_QUERY,
  HIGHLIGHT_SELECTED_CONCEPT_ON_GRAPH,
  RESET_HIGHLIGHTED_CONCEPT_ON_GRAPH,
  UPDATE_QUERY,
  DONE_SAVING_QUERY,
  DONE_SAVING_QUERY_WITH_ERROR,
  IS_QUESTION_FILTERS_MODAL_OPEN,
} from './actions.js';
import { getPropertyListWithDC } from '../../../Modules/Studio/DomainEditor/Toolbar/PropsList/helpers';
import { initialState } from './constants/queryCreation.initialState';
import {
  getQueryMaps,
  resetSelectedConcept,
  updateSelectedConcept,
  getNodesMapAndSuperclasses,
  redrawGraphLinks,
  getFilterPropDeps,
} from './utils';
import { SELF_JOIN_RELATION_NAME } from './constants/queryCreation.constants';
import { sortSelectedProps } from './utils/sortSelectedProps';

let queryGraphOptions = {};
let updatedQueryGraphSeries = [];
let combinedProperties = [];
let allProps = [];
let maps = {};
let questionConcepts = [];
let filterPropDeps = {};

export const queryMapsBase = {
  queryMap: {},
  conceptInstanceIdMap: {},
};

export function queryCreationReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_QUERY_STATE:
      return initialState;

    case SET_DOMAINS:
      const nodesMapAndSupers = getNodesMapAndSuperclasses(action.payload);
      return {
        ...state,
        domains: action.payload.sort((a, b) => a.name.localeCompare(b.name)),
        nodesMap: nodesMapAndSupers.nodesMap,
        superClassMap: nodesMapAndSupers.superClassMap,
      };

    case SET_DOMAINS_HAS_LOADING_ERROR:
      return {
        ...state,
        domainsHasLoadingError: action.payload,
      };

    case SET_DOMAINS_IS_LOADING:
      return {
        ...state,
        domainsIsLoading: action.payload,
      };

    case SET_DOMAINS_WITH_CONCEPT_RELATIONS:
      const domainsWithConceptRelations = getPropertyListWithDC(action.payload) || [];
      return {
        ...state,
        domainsWithConceptRelations,
      };

    case SET_DOMAINS_WITH_CONCEPT_RELATIONS_HAS_LOADING_ERROR:
      return {
        ...state,
        domainsWithConceptRelationsHasLoadingError: action.payload,
      };

    case SET_DOMAINS_WITH_CONCEPT_RELATIONS_IS_LOADING:
      return {
        ...state,
        domainsWithConceptRelationsIsLoading: action.payload,
      };

    case SET_QUERY_BY_ID:
      maps = action.payload.definition
        ? getQueryMaps(action.payload.definition, queryMapsBase)
        : queryMapsBase;

      // SHAME: if there is an error in getQueryMaps, explicitly return error condition, because error is not being thrown;
      // this way it doesn't appear the user lost their data
      if (maps.hasError) {
        return {
          ...state,
          queryHasLoadingError: true,
        };
      }

      queryGraphOptions = getQueryGraphOptions(
        maps.queryMap,
        state.nodesMap,
        undefined,
        action.payload.subQueries
      );

      questionConcepts = Object.keys(action.payload?.definition || {});
      const isQueryEmpty = questionConcepts.length === 0;
      filterPropDeps = getFilterPropDeps(action.payload?.filters || []);

      return {
        ...state,
        query: action.payload,
        queryMap: maps.queryMap,
        queryGraphOptions,
        conceptInstanceIdMap: maps.conceptInstanceIdMap,
        isQueryEmpty,
        isModelGraphOpen: isQueryEmpty, // auto show the add concept modal if query is empty
        filterPropDeps,
      };

    case SET_QUERY_BY_ID_IS_LOADING:
      return {
        ...state,
        queryIsLoading: action.payload,
      };

    case SET_QUERY_BY_ID_HAS_LOADING_ERROR:
      return {
        ...state,
        queryHasLoadingError: action.payload.status,
        queryErrorStatus: action.payload.error.status,
      };

    case TOGGLE_MODEL_GRAPH:
      return {
        ...state,
        isModelGraphOpen: !state.isModelGraphOpen,
      };

    case CLEAR_SELECTED_CONCEPT:
      return {
        ...state,
        selectedConcept: {},
        selectedConceptId: null,
        selectedConceptColor: '',
        filteredCalcsList: state.calcsList,
      };

    /**
     * From the <EditQuery /> component in the useEffect hook:
     * This gets triggered when the user clicks on a parent question concept
     * OR the question is saved.
     * It iterates the query to pull out all of the available properties/relations/selfJoins & added properties/relations/selfJoins
     * in a question
     */
    case UPDATE_SELECTED_CONCEPT_PROPERTIES:
      // combine properties & relations since they are displayed in the same list
      const selectedConcept = state.queryMap[action.payload.selectedConceptId];

      // iterate existing added properties to add isExisting: true & populate existingPropsMap
      const selectedConceptProperties = selectedConcept.properties.reduce(
        (acc, prop) => {
          return {
            existingProperties: [
              ...acc.existingProperties,
              {
                ...prop,
                isExisting: true,
                conceptId: action.payload.selectedConceptId,
                conceptName: selectedConcept.label,
                isRelation: false,
                selfJoin: false,
              },
            ],
            existingPropsMap: {
              ...acc.existingPropsMap,
              [prop.uri]: true,
            },
          };
        },
        {
          existingProperties: [],
          existingPropsMap: {},
        }
      );

      // iterate to get all relations & selfJoins,
      // pass in the selectedConceptProperties
      combinedProperties = Object.keys(selectedConcept.relations).reduce((acc, curr) => {
        const rel = selectedConcept.relations[curr];
        if (curr !== SELF_JOIN_RELATION_NAME) {
          return {
            existingProperties: [
              ...acc.existingProperties,
              {
                isRelation: true,
                label: rel.label,
                relationTypeUri: rel.relationTypeUri,
                uri: rel.uri,
                selfJoin: false,
                conceptId: action.payload.selectedConceptId,
                isExisting: true,
                subQuestionUri: rel.subQueryUri,
                parentQueryId: rel.parentQueryId,
              },
            ],
            existingPropsMap: {
              ...acc.existingPropsMap,
              [rel.uri]: true,
            },
          };
        } else {
          const instances = selectedConcept.relations[curr].relationInstances.reduce(
            (instancesAcc, item) => {
              return {
                existingInstances: [
                  ...instancesAcc.existingInstances,
                  {
                    label: item.label,
                    relationTypeUri: item.relationTypeUri, //relation
                    uri: action.payload.selectedConceptId, //self
                    conceptId: action.payload.selectedConceptId,
                    selfJoin: true, //signals its a Join
                    isRelation: true,
                    isExisting: true,
                    subQuestionUri: item.subQueryUri,
                    parentQueryId: item.parentQueryId,
                  },
                ],
                existingInstancesMap: {
                  ...instancesAcc.existingInstancesMap,
                  [action.payload.selectedConceptId]: true,
                },
              };
            },
            { existingInstances: [], existingInstancesMap: {} }
          );

          return {
            existingProperties: [...acc.existingProperties, ...instances.existingInstances],
            existingPropsMap: {
              ...acc.existingPropsMap,
              ...instances.existingInstancesMap,
            },
          };
        }
      }, selectedConceptProperties);

      // iterate original Ontology's properties and add in the ones that are not yet added to the query (so the user can add them as an option)
      allProps = state.nodesMap[selectedConcept.uri].properties.reduce((acc, curr) => {
        if (!(curr.uri in combinedProperties.existingPropsMap)) {
          return [
            ...acc,
            {
              ...curr,
              truncateTo: null,
              round: null,
              isExisting: false,
              selfJoin: false,
              conceptId: action.payload.selectedConceptId,
              conceptName: selectedConcept.label,
            },
          ];
        } else {
          return acc;
        }
      }, combinedProperties.existingProperties);

      // Find similar concepts w/in the question to add as self join props
      Object.keys(state.queryMap).forEach((key) => {
        let uri = state.queryMap[key].uri;

        if (uri === selectedConcept.uri && key !== action.payload.selectedConceptId) {
          let selfJoinRelations = selectedConcept.relations[SELF_JOIN_RELATION_NAME]
            ? selectedConcept.relations[SELF_JOIN_RELATION_NAME].relationInstances
            : [];

          //Don't show if it exists
          if (selfJoinRelations.filter((item) => item.relationTypeUri === key).length === 0) {
            let selfJoinProp = {
              label: selectedConcept.label,
              relationTypeUri: key, //relation
              uri: action.payload.selectedConceptId, //self
              isExisting: false,
              selfJoin: true, //signals its a Join
              isRelation: true,
              subQuestionUri: null,
              parentQueryId: null,
              conceptId: action.payload.selectedConceptId,
            };

            allProps.push(selfJoinProp);
          }
        }

        // iterate subQuestions to find selfJoins
        if (state.queryMap[key].subQueryId) {
          Object.keys(state.queryMap[key].subQueryDefinition).forEach((subQueryConcept) => {
            // repeat the same steps as above, to get selfJoins from the parent to the subQuestion
            let subQueryConceptUri = state.queryMap[key].subQueryDefinition[subQueryConcept].uri;

            if (
              subQueryConceptUri === selectedConcept.uri &&
              subQueryConcept !== action.payload.selectedConceptId
            ) {
              let selfJoinRelations = selectedConcept.relations[SELF_JOIN_RELATION_NAME]
                ? selectedConcept.relations[SELF_JOIN_RELATION_NAME].relationInstances
                : [];

              //Don't show if it exists
              if (
                selfJoinRelations.filter((item) => item.relationTypeUri === subQueryConcept)
                  .length === 0
              ) {
                let selfJoinProp = {
                  label: selectedConcept.label,
                  relationTypeUri: subQueryConcept, //relation
                  uri: action.payload.selectedConceptId, //self
                  isExisting: false,
                  selfJoin: true, //signals its a Join
                  isRelation: true,
                  subQuestionUri: key,
                  conceptId: action.payload.selectedConceptId,
                };

                allProps.push(selfJoinProp);
              }
            }
          });
        }
      });

      const onlyProperties = sortSelectedProps(
        allProps.filter((prop) => !prop.isRelation && !prop.selfJoin)
      );
      const onlyRelationsOrSelfJoins = sortSelectedProps(
        allProps.filter((prop) => prop.isRelation || prop.selfJoin)
      );

      return {
        ...state,
        selectedConcept: {
          ...selectedConcept,
          properties: allProps,
          onlyProperties,
          onlyRelationsOrSelfJoins,
        },
      };

    /**
     * This gets called when the user clicks on parent query concept
     */
    case SET_SELECTED_CONCEPT_FOR_QUERY:
      return {
        ...state,
        selectedConceptColor: action.payload.color,
        selectedConceptId: action.payload.selectedConceptId,
        filteredCalcsList: state.calcsList.filter((item) => {
          const index = item.arguments.findIndex(
            (arg) => arg.concept === action.payload.selectedConceptId
          );
          return index > -1;
        }),
      };

    case INIT_SAVING_QUERY:
      return {
        ...state,
        queryIsDoneSaving: false,
        queryIsSaving: true,
        queryHasSavingError: false,
      };

    case UPDATE_QUERY:
      try {
        const { query } = action.payload;

        // update queryMap
        maps = getQueryMaps(query.definition, queryMapsBase);
        filterPropDeps = getFilterPropDeps(query?.filters || []);

        let stateUpdates = {
          ...state,
          query,
          queryMap: maps.queryMap,
          filterPropDeps,
        };

        const { updateGraph, updateGraphLinks, isSubQuestion, selectedConceptId } =
          action.payload.additionalProps;

        // update question graph, if needed
        if (updateGraph) {
          let updatedGraphOpts = getQueryGraphOptions(
            maps.queryMap,
            state.nodesMap,
            undefined,
            action.payload.query?.subQueries
          );

          if (selectedConceptId) {
            // keep selected concept active
            updatedGraphOpts = {
              ...updatedGraphOpts,
              series: updateSelectedConcept(selectedConceptId, updatedGraphOpts.series),
            };
          }

          stateUpdates = {
            ...stateUpdates,
            queryGraphOptions: updatedGraphOpts,
          };
        }

        // update question graph links, if needed
        if (updateGraphLinks) {
          stateUpdates = {
            ...stateUpdates,
            queryGraphOptions: redrawGraphLinks({
              definition: query.definition,
              graphOptions: state.queryGraphOptions,
              subQueries: query.subQueries || {},
              isSubQuestion,
            }),
          };
        }

        return stateUpdates;
      } catch (e) {
        console.warn(e);
        return state;
      }

    case DONE_SAVING_QUERY:
      return {
        ...state,
        queryIsDoneSaving: true,
        queryIsSaving: false,
      };

    case DONE_SAVING_QUERY_WITH_ERROR:
      return {
        ...state,
        queryIsDoneSaving: true,
        queryIsSaving: false,
        queryHasSavingError: true,
      };

    case SAVE_QUERY_IS_DONE_SAVING:
      return {
        ...state,
        queryIsDoneSaving: action.payload,
      };

    case SAVE_QUERY_IS_SAVING:
      return {
        ...state,
        queryIsSaving: action.payload,
      };

    case SAVE_QUERY_HAS_SAVING_ERROR:
      return {
        ...state,
        queryHasSavingError: action.payload,
      };

    case SET_SAVED_QUERY_ID:
      maps = action.payload.queryById.definition
        ? getQueryMaps(action.payload.queryById.definition, queryMapsBase)
        : queryMapsBase;

      // SHAME: if there is an error in getQueryMaps, explicitly return error condition, because error is not being thrown;
      // this way it doesnt appear the user lost their data
      if (maps.hasError) {
        return {
          ...state,
          queryHasSavingError: true,
        };
      }

      queryGraphOptions = getQueryGraphOptions(
        maps.queryMap,
        state.nodesMap,
        undefined,
        action.payload.queryById?.subQueries
      );

      questionConcepts = Object.keys(action.payload.queryById.definition || {});

      const isQueryEmptySetSavedQueryID = questionConcepts.length === 0;

      filterPropDeps = getFilterPropDeps(action.payload?.queryById?.filters || []);

      return {
        ...state,
        query: action.payload.queryById,

        queryMap: maps.queryMap,
        queryGraphOptions,
        conceptInstanceIdMap: maps.conceptInstanceIdMap,
        isQueryEmpty: isQueryEmptySetSavedQueryID,
        filterPropDeps,

        // re-initialize all other props
        selectedConcept: action.payload.keepSelectedConcept ? state.selectedConcept : {},
        selectedConceptColor: action.payload.keepSelectedConcept ? state.selectedConceptColor : '',
        selectedConceptId: action.payload.keepSelectedConcept ? state.selectedConceptId : null,

        // This is required as we dont just close the modal when the query is saved, we show a success page
        // When the user removes a concept and the resulting query is empty, the user is unable to close the
        // success page modal themselves since the PropertiesList component is not rendered
        isConfirmDeleteModalOpen: isQueryEmptySetSavedQueryID
          ? false
          : state.isConfirmDeleteModalOpen,
        isConceptSettingsModalOpen: isQueryEmptySetSavedQueryID
          ? false
          : state.isConceptSettingsModalOpen,
      };

    case SET_CALCS_LIST:
      const sortByDescription = (a, b) => {
        const descriptionA = a.description || '';
        const descriptionB = b.description || '';
        return descriptionA.localeCompare(descriptionB);
      };
      return {
        ...state,
        calcsList: action.payload,
        filteredCalcsList:
          state.selectedConceptId === null
            ? action.payload.sort(sortByDescription)
            : action.payload
                .filter((item) => {
                  const index = item.arguments.findIndex(
                    (arg) => arg.concept === state.selectedConceptId
                  );
                  return index > -1;
                })
                .sort(sortByDescription),
      };

    case SET_CALCS_LIST_IS_LOADING:
      return {
        ...state,
        calcsListIsLoading: action.payload,
      };

    case SET_CALCS_LIST_HAS_ERROR:
      return {
        ...state,
        calcsListHasError: action.payload,
      };

    case TOGGLE_RUNTIME_SETTINGS_MODAL:
      return {
        ...state,
        runtimeSettingsModalStatus: action.payload,
      };

    case IS_PROPERTY_ENTITY:
      return {
        ...state,
        isPropertyEntity: action.isPropertyEntity,
      };

    case IS_CONCEPT_SETTINGS_MODAL_OPEN:
      return {
        ...state,
        isConceptSettingsModalOpen: action.isConceptSettingsModalOpen,
      };

    case UPDATE_QUERY_WITH_ADDED_SUB_QUERY:
      maps = getQueryMaps(action.payload.query.definition, queryMapsBase);

      return {
        ...state,
        queryMap: maps.queryMap,
        query: action.payload.query,
        queryGraphOptions: action.payload.updatedQueryGraphOptions,
        isQueryEmpty: false,
      };

    case HIGHLIGHT_SELECTED_CONCEPT_ON_GRAPH:
      updatedQueryGraphSeries = updateSelectedConcept(
        action.payload.selectedConceptId,
        state.queryGraphOptions.series
      );

      return {
        ...state,
        queryGraphOptions: {
          ...state.queryGraphOptions,
          series: updatedQueryGraphSeries,
        },
      };

    case RESET_HIGHLIGHTED_CONCEPT_ON_GRAPH:
      updatedQueryGraphSeries = resetSelectedConcept(state.queryGraphOptions.series);

      return {
        ...state,
        queryGraphOptions: {
          ...state.queryGraphOptions,
          series: updatedQueryGraphSeries,
        },
      };

    case IS_QUESTION_FILTERS_MODAL_OPEN:
      return {
        ...state,
        isQuestionFiltersModalOpen: action.payload,
      };

    default:
      return state;
  }
}
