import { Navigate, Outlet, useParams } from 'react-router-dom-v5-compat';
import { Suspense } from 'react';

import { HomeRoutes, StudioRoutes } from '../../../../RouteConstants';
import { IRoutes } from '@components/3.0/Layouts/TabbedLayout/utils/constants/routes.interface';
import ErrorPage from '@components/ErrorBoundry/ErrorPage';
import { LoadingIndicator } from '@components/LoadingIndicatorMaterial';

// redirect for old route, in case someone has a domain id bookmarked
const EditDomainRedirect = () => {
  const params = useParams();
  return <Navigate to={`${StudioRoutes.editDomain.base}/${params.domainId}`} />;
};

export const editDomainRoutes: IRoutes[] = [
  {
    routeItem: {
      path: `${StudioRoutes.model.base}/*`,
      element: <Outlet />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: '',
          async lazy() {
            const Domains = (await import('Modules/Studio/Domains/Domains')).default;
            const ToggleComponentBasedOnPermission = (
              await import('RBAC/ToggleComponentBasedOnPermission')
            ).default;

            return {
              element: (
                <ToggleComponentBasedOnPermission
                  IsAllowed={Domains}
                  IsNotAllowed={() => <Navigate to={`/${HomeRoutes.notAuthorized}`} />}
                  permissionRef={StudioRoutes.model.permission}
                />
              ),
              errorElement: <ErrorPage />,
            };
          },
        },
        {
          path: `${StudioRoutes.editDomain.base}${StudioRoutes.editDomain.basePathVariables}/*`,
          children: [
            {
              path: '',
              async lazy() {
                const EditDomain = (await import('Modules/Studio/DomainEditor/EditDomain')).default;

                return {
                  element: (
                    <Suspense fallback={<LoadingIndicator isLoading />}>
                      <EditDomain />
                    </Suspense>
                  ),
                  errorElement: <ErrorPage />,
                };
              },
            },
            {
              path: `${StudioRoutes.editDomainMapping.base}${StudioRoutes.editDomainMapping.basePathVariables}`,
              id: 'mappingViewByConceptUri',
              async lazy() {
                const EditDomainMappingView = (
                  await import('Modules/Studio/Mappings/EditDomainMappingView')
                ).default;
                const { fetchMappingListByConceptUri } = await import(
                  'Modules/Studio/Mappings/routing/fetchMappingListByConceptUri.loader'
                );

                return {
                  element: (
                    <Suspense fallback={<LoadingIndicator isLoading />}>
                      <EditDomainMappingView />
                    </Suspense>
                  ),
                  loader: fetchMappingListByConceptUri,
                  errorElement: <ErrorPage />,
                };
              },
            },
          ],
          async lazy() {
            const DomainDetails = (await import('Modules/Studio/DomainEditor/DomainDetails'))
              .default;

            return {
              element: (
                <Suspense fallback={<LoadingIndicator isLoading />}>
                  <DomainDetails />
                </Suspense>
              ),
              errorElement: <ErrorPage />,
            };
          },
        },
        {
          path: `edit-domain${StudioRoutes.editDomain.basePathVariables}`,
          element: <EditDomainRedirect />,
          errorElement: <ErrorPage />,
        },
      ],
    },
    permissionRef: StudioRoutes.model.permission,
    featureFlag: true,
  },
];
